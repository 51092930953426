import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_3 = { class: "grid p-1 m-0 col-12 md:col-6 xl:col-4" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_6 = { class: "p-inputgroup" }
const _hoisted_7 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_8 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_9 = {
  key: 0,
  class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4"
}
const _hoisted_10 = {
  key: 1,
  class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4"
}
const _hoisted_11 = {
  key: 2,
  class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4"
}
const _hoisted_12 = {
  key: 3,
  class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4"
}
const _hoisted_13 = { class: "image-carousel" }
const _hoisted_14 = { class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4" }
const _hoisted_15 = { style: {"width":"100%"} }
const _hoisted_16 = { class: "grid p-1 m-0 col-12 md:col-12 lg:col-6 xl:col-4" }
const _hoisted_17 = { style: {"width":"100%"} }
const _hoisted_18 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_19 = { class: "p-inputgroup" }
const _hoisted_20 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_21 = {
  key: 0,
  class: "col-1 divider-header p-0 mt-2 mr-2 ml-1 font-medium"
}
const _hoisted_22 = {
  key: 1,
  class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2"
}
const _hoisted_23 = { class: "p-inputgroup" }
const _hoisted_24 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}
const _hoisted_25 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_26 = { class: "grid p-1 m-0 col-12" }
const _hoisted_27 = { class: "grid p-1 m-0 col-12" }
const _hoisted_28 = { class: "grid p-1 m-0 col-12" }
const _hoisted_29 = { class: "grid p-0 m-0 mt-2 justify-content-left pl-1 pr-2" }
const _hoisted_30 = { class: "grid p-1 m-0 col-12 md:col-12 xl:col-8" }
const _hoisted_31 = {
  key: 0,
  class: "grid p-1 m-0 col-12 md:col-12 xl:col-4"
}
const _hoisted_32 = { class: "image-carousel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_RoverGrid = _resolveComponent("RoverGrid")!
  const _component_Image = _resolveComponent("Image")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.allowSave)
      ? (_openBlock(), _createBlock(_component_SplitButton, {
          key: 0,
          label: "Save",
          icon: _ctx.saveButtonIcon,
          iconPos: "left",
          class: "text-sm font-bold align-self-center collapsible-mobile-view",
          style: {"float":"right"},
          onClick: _ctx.savePart,
          model: _ctx.splitButtonItems,
          disabled: _ctx.loading
        }, null, 8, ["icon", "onClick", "model", "disabled"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sections.general,
      header: "General",
      "onUpdate:isOpen": _cache[6] || (_cache[6] = ($event: any) => (_ctx.sections.general = !_ctx.sections.general))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.idField.display_name), 1),
              _createVNode(_component_RoverInput, {
                field: _ctx.idField,
                modelValue: (_ctx.partInput as any)[_ctx.idField.json_name],
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (((_ctx.partInput as any)[_ctx.idField.json_name]) = $event)),
                disabled: _ctx.getActivePart!.old_record.part_no != undefined
              }, null, 8, ["field", "modelValue", "disabled"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generalFields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "grid p-1 m-0 col-12 md:col-6 xl:col-4",
              key: field.field_no
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("small", _hoisted_7, _toDisplayString(field.display_name), 1),
                _createVNode(_component_RoverInput, {
                  field: field,
                  modelValue: (_ctx.partInput as any)[field.json_name],
                  "onUpdate:modelValue": ($event: any) => (((_ctx.partInput as any)[field.json_name]) = $event),
                  disabled: _ctx.disableEdits
                }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "disabled"])
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.getDrawingNumberFields.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_RoverGrid, {
                  fields: _ctx.getDrawingNumberFields,
                  dataArray: _ctx.partInput.drawing_number_items,
                  onUpdateArray: _cache[1] || (_cache[1] = ($event: any) => (_ctx.partInput.drawing_number_items = $event)),
                  editable: !_ctx.disableEdits
                }, null, 8, ["fields", "dataArray", "editable"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.getAltPartNoFields.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_RoverGrid, {
                  fields: _ctx.getAltPartNoFields,
                  dataArray: _ctx.partInput.alt_part_no_items,
                  onUpdateArray: _cache[2] || (_cache[2] = ($event: any) => (_ctx.partInput.alt_part_no_items = $event)),
                  editable: !_ctx.disableEdits
                }, null, 8, ["fields", "dataArray", "editable"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.getEquivPartFields.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_RoverGrid, {
                  fields: _ctx.getEquivPartFields,
                  dataArray: _ctx.partInput.equiv_part_items,
                  onUpdateArray: _cache[3] || (_cache[3] = ($event: any) => (_ctx.partInput.equiv_part_items = $event)),
                  editable: !_ctx.disableEdits
                }, null, 8, ["fields", "dataArray", "editable"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.partInput.image_desc_items)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_Carousel, {
                  value: _ctx.partInput.image_desc_items,
                  numVisible: 1,
                  numScroll: 1,
                  showIndicators: "",
                  showNavigators: ""
                }, {
                  header: _withCtx(() => _cache[14] || (_cache[14] = [])),
                  item: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_Image, {
                        src: slotProps.data.image_path,
                        alt: slotProps.data.image_desc,
                        height: "200",
                        preview: ""
                      }, null, 8, ["src", "alt"])
                    ])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[15] || (_cache[15] = _createElementVNode("label", {
                for: "description",
                class: "font-semibold"
              }, "Description", -1)),
              _createVNode(_component_Textarea, {
                id: "description",
                modelValue: _ctx.partInput.wrap_desc,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.partInput.wrap_desc) = $event)),
                rows: "5",
                disabled: _ctx.disableEdits
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[16] || (_cache[16] = _createElementVNode("label", {
                for: "description",
                class: "font-semibold"
              }, "Notes", -1)),
              _createVNode(_component_Textarea, {
                id: "description",
                modelValue: _ctx.partInput.notes,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.partInput.notes) = $event)),
                rows: "5",
                disabled: _ctx.disableEdits
              }, null, 8, ["modelValue", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sections.material_control,
      header: "Material Control",
      "onUpdate:isOpen": _cache[7] || (_cache[7] = ($event: any) => (_ctx.sections.material_control = !_ctx.sections.material_control))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.materialControlFields, (field) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "grid p-1 m-0 col-12 md:col-4 xl:col-3",
              key: field.field_no
            }, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("small", _hoisted_20, _toDisplayString(field.display_name), 1),
                _createVNode(_component_RoverInput, {
                  field: field,
                  modelValue: (_ctx.partInput as any)[field.json_name],
                  "onUpdate:modelValue": ($event: any) => (((_ctx.partInput as any)[field.json_name]) = $event),
                  disabled: _ctx.disableEdits
                }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "disabled"])
              ])
            ]))
          }), 128))
        ]),
        (_ctx.showPlanGroup)
          ? (_openBlock(), _createElementBlock("small", _hoisted_21, " Planning Group "))
          : _createCommentVNode("", true),
        (_ctx.showPlanGroup)
          ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planningInformationFields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "grid p-1 m-0 col-12 md:col-4 xl:col-3",
                  key: field.field_no
                }, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("small", _hoisted_24, _toDisplayString(field.display_name), 1),
                    _createVNode(_component_RoverInput, {
                      field: field,
                      modelValue: _ctx.partInput.plan_group_items[0][field.json_name],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.partInput.plan_group_items[0][field.json_name]) = $event),
                      disabled: _ctx.disableEdits
                    }, null, 8, ["field", "modelValue", "onUpdate:modelValue", "disabled"])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sections.customer_supplier,
      header: "Customer/Supplier",
      "onUpdate:isOpen": _cache[11] || (_cache[11] = ($event: any) => (_ctx.sections.customer_supplier = !_ctx.sections.customer_supplier))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_RoverGrid, {
              fields: _ctx.getManufacturerNameFields,
              dataArray: _ctx.partInput.mfg_name_items,
              onUpdateArray: _cache[8] || (_cache[8] = ($event: any) => (_ctx.partInput.mfg_name_items = $event)),
              editable: !_ctx.disableEdits
            }, null, 8, ["fields", "dataArray", "editable"])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_RoverGrid, {
              fields: _ctx.getCustItemFields,
              dataArray: _ctx.partInput.cust_items,
              onUpdateArray: _cache[9] || (_cache[9] = ($event: any) => (_ctx.partInput.cust_items = $event)),
              editable: !_ctx.disableEdits
            }, null, 8, ["fields", "dataArray", "editable"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_RoverGrid, {
              fields: _ctx.getVendorNumberFields,
              dataArray: _ctx.partInput.vendor_items,
              onUpdateArray: _cache[10] || (_cache[10] = ($event: any) => (_ctx.partInput.vendor_items = $event)),
              editable: !_ctx.disableEdits
            }, null, 8, ["fields", "dataArray", "editable"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sections.images,
      header: "Images",
      "onUpdate:isOpen": _cache[13] || (_cache[13] = ($event: any) => (_ctx.sections.images = !_ctx.sections.images))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_component_RoverGrid, {
              fields: _ctx.imageFields,
              dataArray: _ctx.partInput.image_desc_items,
              onUpdateArray: _cache[12] || (_cache[12] = ($event: any) => (_ctx.partInput.image_desc_items = $event)),
              editable: !_ctx.disableEdits
            }, null, 8, ["fields", "dataArray", "editable"])
          ]),
          (_ctx.partInput.image_desc_items)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _createVNode(_component_Carousel, {
                  value: _ctx.partInput.image_desc_items,
                  numVisible: 1,
                  numScroll: 1,
                  showIndicators: "",
                  showNavigators: ""
                }, {
                  header: _withCtx(() => _cache[17] || (_cache[17] = [])),
                  item: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_Image, {
                        src: slotProps.data.image_path,
                        alt: slotProps.data.image_desc,
                        height: "200",
                        preview: ""
                      }, null, 8, ["src", "alt"])
                    ])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["isOpen"])
  ]))
}